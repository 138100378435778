import React, { useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DesktopFinish from "./containers/desktop/Finish";
import DesktopForm from "./containers/desktop/Form";
import DesktopMain from "./containers/desktop/Main";
import MobileFinish from "./containers/mobile/Finish";
import MobileForm from "./containers/mobile/Form";
import MobileMain from "./containers/mobile/Main";
import DesktopRightLogo from "./assets/desktop-right.svg";

const renderDesktop = (
  language: string,
  setLanguage: (language: string) => void
) => (
  <div className="content-wrapper">
    <div className="content-wrapper__left">
      <Router>
        <Switch>
          <Route exact path="/">
            <DesktopMain language={language} setLanguage={setLanguage} />
          </Route>
          <Route exact path="/form">
            <DesktopForm language={language} />
          </Route>
          <Route exact path="/form/nexity">
            <DesktopForm language={language} />
          </Route>
          <Route path="/finish">
            <DesktopFinish language={language} />
          </Route>
        </Switch>
      </Router>
    </div>
    <div className="content-wrapper__right">
      <img
        className="f-container__tick-logo"
        src={DesktopRightLogo}
        alt="Tick Logo"
      />
    </div>
  </div>
);

const renderMobile = (
  language: string,
  setLanguage: (language: string) => void
) => (
  <Router>
    <Switch>
      <Route exact path="/">
        <MobileMain language={language} setLanguage={setLanguage} />
      </Route>
      <Route exact path="/form">
        <MobileForm language={language} />
      </Route>
      <Route exact path="/form/nexity">
        <MobileForm language={language} />
      </Route>
      <Route path="/finish">
        <MobileFinish language={language} />
      </Route>
    </Switch>
  </Router>
);

const getBrowserLanguage = (): string => {
  try {
    const language = navigator.language || "en-US";
    return language.split("-")[0];
  } catch (e) {
    return "en-US";
  }
};

const App = () => {
  const [language, setLanguage] = useState(getBrowserLanguage());

  return (
    <div>
      {isMobile && !isTablet && renderMobile(language, setLanguage)}
      {isTablet && renderDesktop(language, setLanguage)}
      {!isMobile && !isTablet && renderDesktop(language, setLanguage)}
    </div>
  );
};

export default App;
