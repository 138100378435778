import React, { FC } from "react";
import NexityLogo from "../../assets/nexity-logo.svg";
import NexityWhiteLogo from "../../assets/nexity-white-logo.svg";
import InnogyLogo from "../../assets/operators/innogy-logo.svg";
import { useHistory } from "react-router-dom";
import { getLanguageJSON } from "../../lib/utils";
import LanguageSelect from "../../components/languageSelect";
import { Main } from "../../types/language";
import CallLogo from "../../assets/call.svg";
import NexityBackground from "../../assets/nexity-logo-mobile.svg";
import ReactTooltip from "react-tooltip";
import QuestionLogo from "../../assets/question-logo.svg";

const MobileMain: FC<{
  language: string;
  setLanguage: (language: string) => void;
}> = (props) => {
  const history = useHistory();
  const handleToClick = (to: string) => history.push(to);

  const languageJSON: Main | null = getLanguageJSON<Main>({
    language: props.language,
    module: "main",
  });

  return (
    <div className="m-container">
      {/* <div className="m-container__charger">
        <img src={Charger} alt="Charger" className="charger-logo" />
      </div> */}
      <img
        className="m-container__background"
        src={NexityBackground}
        alt="Nexity"
      />
      <div className="m-container__language">
        <LanguageSelect
          language={props.language}
          setLanguage={props.setLanguage}
        />
      </div>
      <div className="m-container__m-header">
        <div className="m-container__m-header__nexity">
          <img
            className="m-container__m-header__logo"
            src={NexityLogo}
            alt="Nexity Logo"
          />
          <h1 className="m-container__m-header__title">
            {languageJSON?.welcome1} {languageJSON?.welcome2}
            <br />
            {languageJSON?.welcome3}
          </h1>
        </div>
      </div>
      <h2 className="m-container__create">{languageJSON?.create}</h2>
      <button
        onClick={() => handleToClick("/form/nexity")}
        className="m-operator__button m-operator__button--green"
      >
        <div className="m-operator__button__content m-operator__button__content--white">
          <div
            style={{ marginTop: "0.5em" }}
            className="m-operator__button__content__item--logo"
          >
            <img src={NexityWhiteLogo} alt="Nexity Logo" />
          </div>
          <div className="m-operator__button__content__item--name">
            {languageJSON?.platformNexity}
          </div>
        </div>
      </button>
      {/*<div className="m-container__m-header__join">
        {languageJSON?.alternative}
      </div>
      <div className="m-operator">
        <button
          onClick={(e: any) => {
            if (e.target.id !== "tooltip") handleToClick("/form/innogy");
          }}
          className="m-operator__button m-operator__button--white"
        >
          <div className="m-operator__button__content m-operator__button__content--gray">
            <div className="m-operator__button__content__item--logo">
              <img src={InnogyLogo} alt="Nexity Logo" />
            </div>
            <div className="m-operator__button__content__item--name">
              {languageJSON?.inngoy}
              <img
                id="tooltip"
                className="d-operator__button__content__item--name-container__question"
                data-tip
                data-for="tooltip"
                src={QuestionLogo}
                alt="Info"
              />
              <ReactTooltip
                className="tooltip"
                id="tooltip"
                type="light"
                effect="solid"
              >
                {languageJSON?.tooltipInnogy}
              </ReactTooltip>
            </div>
          </div>
        </button>
      </div>*/}
      <div className="d-container__contact">
        <img
          className="d-container__contact__call-logo"
          src={CallLogo}
          alt="call"
        />
        <div>
          <span className="d-container__contact__text">
            {languageJSON?.problems}
          </span>
          <a
            href={`tel:${languageJSON?.call.replace(/\s/g, "")}`}
            className="d-container__contact__number"
          >
            {languageJSON?.callInfo} {languageJSON?.call}
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileMain;
