import React, { FC } from "react";
import MobileForm from "../mobile/Form";

const DesktopForm: FC<{ language: string }> = (props) => (
  <div className="form-container">
    <div style={{ width: "27.1rem" }}>
      <MobileForm language={props.language} />
    </div>
  </div>
);

export default DesktopForm;
