import React, { FC } from "react";
import { isMobile } from "react-device-detect";

export interface ToastNotificationProps {
  content: string;
  clear: () => void;
}

const ToastNotification: FC<ToastNotificationProps> = (props) => {
  if (!props.content) return null;

  return (
    <div
      className={
        "nx-toast-container nx-toast-container--error" +
        (isMobile ? " nx-toast-container__mobile" : "")
      }
    >
      <i className="nx-toast-container__icon nx-toast-container__icon--error"></i>
      <p className="nx-toast-container__content">{props.content}</p>
      <i onClick={props.clear} className="nx-toast-container__button"></i>
    </div>
  );
};

export default ToastNotification;
