import React, { FC, useEffect, useState } from "react";
import { TextInputProps } from "../../types/input";

const TextInput: FC<TextInputProps> = (props) => {
  const [validateError, setValidateError] = useState("");

  useEffect(() => {
    setValidateError("");
  }, [props.refresh]);

  const onChange = (e: any) => {
    props.onChange(props.name, e.target.value);
  };

  const onDefocus = (e: any) => {
    const value = e.target.value;

    if (props.validateRegex)
      if (value && !value.match(props.validateRegex)) {
        setValidateError(props.validateError || "");
        if (props.setValidation) props.setValidation(props.name, false);
      } else {
        setValidateError("");
        if (props.setValidation) props.setValidation(props.name, true);
      }
  };

  return (
    <label className="label">
      <span className="label__description">{props.label}</span>
      <div className="label__field">
        <input
          type={props.type || "text"}
          name={props.name}
          onChange={onChange}
          onBlur={onDefocus}
          placeholder={props.placeholder}
          autoComplete={props.type === "password" ? "off" : "on"}
          className={
            "label__field__input" +
            ((props.formError && !props.formData[props.name]) || validateError
              ? " error"
              : "")
          }
          required
          value={props.formData[props.name] || ""}
        />
      </div>
      <span className="label__error">{validateError}</span>
    </label>
  );
};

export default TextInput;
