import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CallLogo from "../../assets/call.svg";
import NexityLogo from "../../assets/nexity-logo.svg";
import NexityWhiteLogo from "../../assets/nexity-white-logo.svg";
import InnogyLogo from "../../assets/operators/innogy-logo.svg";
import QuestionLogo from "../../assets/question-logo.svg";
import LanguageSelect from "../../components/languageSelect";
import { getLanguageJSON } from "../../lib/utils";
import { Main } from "../../types/language";

const DesktopMain: FC<{
  language: string;
  setLanguage: (language: string) => void;
}> = (props) => {
  const history = useHistory();
  const handleToClick = (to: string) => history.push(to);
  const languageJSON: Main | null = getLanguageJSON<Main>({
    language: props.language,
    module: "main",
  });

  return (
    <div className="d-container">
      <div style={{ width: "27.1rem" }}>
        <div className="d-container__header">
          <img src={NexityLogo} alt="Nexity Logo" />
          <div className="d-container__language">
            <LanguageSelect
              language={props.language}
              setLanguage={props.setLanguage}
            />
          </div>
        </div>
        <h1 className="d-container__title">
          {languageJSON?.welcome1} <br /> {languageJSON?.welcome2}{" "}
          {languageJSON?.welcome3} <br />
          {languageJSON?.welcome4}
        </h1>
        <h2 className="d-container__create">{languageJSON?.create}</h2>
        <button
          onClick={() => handleToClick("/form/nexity")}
          className="d-operator__button d-operator__button--black"
        >
          <div className="d-operator__button__content d-operator__button__content--white">
            <div
              style={{ marginTop: "0.5em" }}
              className="d-operator__button__content__item--logo"
            >
              <img src={NexityWhiteLogo} alt="Nexity Logo" />
            </div>
            <div className="d-operator__button__content__item--name-container">
              {languageJSON?.platformNexity}
            </div>
          </div>
        </button>
        {/*<h3 className="d-container__join">{languageJSON?.alternative}</h3>
        <div className="d-operator">
          <button
            onClick={(e: any) => {
              if (e.target.id !== "tooltip") handleToClick("/form/innogy");
            }}
            className="d-operator__button d-operator__button--white"
          >
            <div className="d-operator__button__content d-operator__button__content--black">
              <div className="d-operator__button__content__item--logo">
                <img src={InnogyLogo} alt="Nexity Logo" />
              </div>
              <div className="d-operator__button__content__item--name-container">
                {languageJSON?.inngoy}
                <img
                  id="tooltip"
                  className="d-operator__button__content__item--name-container__question"
                  data-tip
                  data-for="tooltip"
                  src={QuestionLogo}
                  alt="Info"
                />
                <ReactTooltip
                  className="tooltip"
                  id="tooltip"
                  type="light"
                  effect="solid"
                  overridePosition={(
                    { left, top },
                    currentEvent,
                    currentTarget,
                    node
                  ) => {
                    const d = document.documentElement;
                    left = Math.min(d.clientWidth - node!.clientWidth, left);
                    top = Math.min(d.clientHeight - node!.clientHeight, top);
                    left = Math.max(0, left) - 47;
                    top = Math.max(0, top);
                    return { top, left };
                  }}
                >
                  {languageJSON?.tooltipInnogy}
                </ReactTooltip>
              </div>
            </div>
          </button>
        </div>*/}
        <div className="d-container__contact">
          <img
            className="d-container__contact__call-logo"
            src={CallLogo}
            alt="call"
          />
          <div>
            <span className="d-container__contact__text">
              {languageJSON?.problems}
            </span>
            <a
              href={`tel:${languageJSON?.call.replace(/\s/g, "")}`}
              className="d-container__contact__number"
            >
              {languageJSON?.callInfo} {languageJSON?.call}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopMain;
