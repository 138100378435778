import React, { FC, useState } from "react";
import Select, { components, ValueType } from "react-select";
import LanguageArrow from "../assets/language-arrow.svg";
import { languageOptions, SelectLanguageType } from "../types/language";

const formatOptionLabel = ({ value, label }: any, { context }: any) => {
  if (context === "menu") {
    return <div>{label}</div>;
  } else if (context === "value") return <div></div>;
};

const LanguageSelect: FC<{
  language: string;
  setLanguage: (language: string) => void;
}> = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState<
    ValueType<SelectLanguageType>
  >({
    label: props.language === "pl" ? "Polski" : "English",
    value: props.language,
  });

  const Input = (props: any) => {
    if (props.isHidden) {
      return (
        <div style={{ color: "transparent" }}>
          <components.Input {...props} />
        </div>
      );
    }
    return (
      <div style={{ border: `none` }}>
        <span style={{ visibility: "visible" }}>
          {/* <div className="language">
            {(currentLanguage as SelectLanguageType).label}
            <img
              className="language__arrow"
              src={LanguageArrow}
              alt="Language arrow"
            />
          </div> */}
        </span>
        <components.Input {...props} style={{ color: "transparent" }} />
      </div>
    );
  };

  const SingleValue = (props: any) => {
    return (
      <div style={{ border: `none` }}>
        <span style={{ visibility: "visible" }}>
          <div className="language">
            {(currentLanguage as SelectLanguageType).label}
            <img
              className="language__arrow"
              src={LanguageArrow}
              alt="Language arrow"
            />
          </div>
        </span>
        <components.SingleValue {...props} style={{ color: "transparent" }} />
      </div>
    );
  };

  // const Menu = (props: any) => {
  //   const optionsLength = getLength(props.options);
  //   return (
  //     <Fragment>
  //       <div style={menuHeaderStyle}>
  //         Custom Menu with {optionsLength} options
  //       </div>
  //       <components.Menu {...props}>{props.children}</components.Menu>
  //     </Fragment>
  //   );
  // };

  const handleChange = (currentLanguage: any) => {
    setCurrentLanguage(currentLanguage);
    props.setLanguage(currentLanguage.value);
  };

  return (
    <Select
      hideSelectedOptions={false}
      placeholder=""
      delimiter=""
      components={{
        // SelectContainer: SelectContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Input,
        SingleValue,
        // Menu,
      }}
      formatOptionLabel={formatOptionLabel}
      value={currentLanguage}
      onChange={handleChange}
      options={languageOptions}
      menuPlacement="auto"
      isSearchable={false}
      styles={{
        control: (provided) => ({
          ...provided,
          boxShadow: "none",
          border: "none",
          background: "none",
          minHeight: "auto",
        }),
        menu: (base: any, state) => ({
          ...base,
          width: "7.8rem",
          padding: "0.3em 0.6rem 0.3em 0.6rem",
          borderRadius: "8px",
          fontSize: "14px",
          border: "1px solid #1e1e1e",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
          lineHeight: "22px",
          fontWeight: "400",
          fontFamily: "Poppins, sans-serif",
          paddingLeft: "0.3rem",
          paddingRight: "0.3rem",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            color: "#1f1f1f",
            backgroundColor: isSelected ? "#E4E4EB" : isFocused ? "none" : "",
            ":active": {
              backgroundColor: "#f4f4f4",
            },
            borderRadius: "4px",
            // marginTop: "0",
            paddingTop: "0.2rem",
            paddingBottom: "0.2rem",
          };
        },
        menuList: (base: any) => ({
          ...base,
        }),
        valueContainer: (base: any) => ({
          ...base,
          paddingLeft: "0",
          marginTop: 0,
          paddingTop: 0,
          alignItems: "flex-start",
        }),
        container: (base: any) => ({ ...base, alignSelf: "flex-start" }),
      }}
    />
  );
};

export default LanguageSelect;
