export interface ConsentProps {
  isConsentRegulationsChecked: boolean;
  isConsentMarketingChecked: boolean;
  isConsentTradingOffersChecked: boolean;
  toggleConsentRegulations: () => void;
  toggleConsentMarketing: () => void;
  toggleConsentTradingOffers: () => void;
  isAllConsentsChecked: boolean;
  toggleAllConsents: () => void;
}

export enum ConsentType {
  MARKETING,
  TRADING,
}
