export interface Options {
  language: string;
  module: "main" | "form" | "finish";
}

export interface Main {
  welcome1: string;
  welcome2: string;
  welcome3: string;
  welcome4: string;
  create: string;
  alternative: string;
  problems: string;
  callInfo: string;
  call: string;
  platformNexity: string;
  inngoy: string;
  tooltipInnogy: string;
}

export interface Form {
  create: string;
  individual: string;
  business: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  companyId: string;
  companyName: string;
  country: string;
  address: string;
  city: string;
  zipCode: string;
  phone: string;
  consentAll: string;
  consentRegulations: string;
  consentMarketing: string;
  consentTradingOffers: string;
  consentConnector: string;
  more: string;
  less: string;
  next: string;
  fieldIsRequired: string;
  validateEmail: string;
  validateNumber: string;
  validatePasswordNexity: string;
  validatePasswordInnogy: string;
  validateZipCode: string;
}

export interface Finish {
  thank: string;
  enjoy1: string;
  enjoy2: string;
  install: string;
}

export interface SelectLanguageType {
  label: string;
  value: string;
}

export const languageOptions = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Polski",
    value: "pl",
  },
];
