import React, { FC } from "react";
import AppStoreLogo from "../../assets/apple-store.svg";
import FinishTick from "../../assets/finish-tick.svg";
import GooglePlayLogo from "../../assets/google-play.svg";
import NexityLogo from "../../assets/nexity-logo.svg";
import { getLanguageJSON } from "../../lib/utils";
import { Finish } from "../../types/language";
import { isMobileOnly } from "react-device-detect";
import classNames from "classnames";

const MobileFinish: FC<{ language: string }> = (props) => {
  const languageJSON: Finish | null = getLanguageJSON<Finish>({
    language: props.language,
    module: "finish",
  });

  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener("popstate", (event) => {
    window.history.pushState("/", document.title, "/");
  });

  return (
    <div
      className={"f-container" + (isMobileOnly ? " f-container__mobile" : "")}
    >
      <img src={NexityLogo} alt="Nexity Logo" />
      <img
        className={classNames("f-container__tick-logo", {
          "f-container__tick-logo--mobile": isMobileOnly,
        })}
        src={FinishTick}
        alt="Tick Logo"
      />
      <h1
        className={classNames("f-container__thank-you", {
          "f-container__thank-you--mobile": isMobileOnly,
        })}
      >
        {languageJSON?.thank}
      </h1>
      <h2
        className={classNames("f-container__enjoy", {
          "f-container__enjoy--mobile": isMobileOnly,
        })}
      >
        {languageJSON?.enjoy1}
        <br />
        {languageJSON?.enjoy2}
      </h2>
      <div
        className={classNames("f-container__install", {
          "f-container__install--mobile": isMobileOnly,
        })}
      >
        {languageJSON?.install}
      </div>
      <img
        style={{
          width: "13em",
        }}
        onClick={() =>
          window.location.assign(
            "https://play.google.com/store/apps/details?id=pl.nexity.echarge"
          )
        }
        className="f-container__google-logo"
        src={GooglePlayLogo}
        alt="Google Play logo"
      />
      <img
        style={{
          width: "13em",
          marginTop: "0.5em",
        }}
        onClick={() =>
          window.location.assign(
            "https://apps.apple.com/app/nexity/id1457564789"
          )
        }
        className="f-container__app-store-logo"
        src={AppStoreLogo}
        alt="App Store Logo"
      />
    </div>
  );
};

export default MobileFinish;
