import React, { FC } from "react";
import BottomArrowPrimary from "../../assets/bottom-arrow-primary.svg";
import PolicyEN from "../../assets/docs/nexity-policy-en.pdf";
import PolicyPL from "../../assets/docs/nexity-policy-pl.pdf";
import RegulationsEN from "../../assets/docs/nexity-regulations-en.pdf";
import RegulationsPL from "../../assets/docs/nexity-regulations-pl.pdf";
import {
  getOperatorClause,
  getOperatorClauseLink,
  getOperatorConsentName,
} from "../../lib/utils";
import { ConsentProps, ConsentType } from "../../types/form";
import { CheckboxProps } from "../../types/input";
import { Form } from "../../types/language";

const parseConsent = (
  consent: string | undefined,
  operatorConsentName: string | null,
  languageJSON: Form | null
) => {
  if (!consent) return "";
  return consent.replace(
    /{%operatorName%}/g,
    operatorConsentName
      ? `${operatorConsentName} ${languageJSON?.consentConnector} `
      : ""
  );
};

const getLinkedConsent = (
  text: string | undefined,
  policyText: string,
  regulationsText: string,
  policyFile: any,
  regulationsFile: any
) => {
  const policySplitted = text?.split("{%policy-file%}");
  const regulationsSplitted = policySplitted![1]?.split("{%regulations-file%}");
  return (
    <span>
      {policySplitted![0]}{" "}
      <a className="regulations-consent-link" target="_blank" href={policyFile}>
        {policyText}
      </a>
      {regulationsSplitted![0]}{" "}
      <a className="regulations-consent-link" target="_blank" href={regulationsFile}>
        {regulationsText}
      </a>
    </span>
  );
};

const getLinkedClause = (text: string, link: string, language: string) => {
  if (!text) return "";

  const splitted = text?.split("{%link%}");
  return (
    <div className="label-container">
      {splitted![0]}{" "}
      <a className="regulations-consent-link" target="_blank" href={link}>
        {language === "pl" ? "tutaj" : "here"}
      </a>
    </div>
  );
};

const renderConsentText = (text: string | undefined, language: string) => {
  switch (language) {
    case "pl":
      return getLinkedConsent(
        text,
        "Politykę Prywatności",
        "Regulamin Świadczenia Usług",
        PolicyPL,
        RegulationsPL
      );
    case "en":
    default:
      return getLinkedConsent(
        text,
        "Privacy Policy",
        "Regulations",
        PolicyEN,
        RegulationsEN
      );
  }
};

const renderConsent = (
  languageJSON: Form | null,
  formError: boolean,
  operator: string,
  language: string,
  consents?: ConsentProps
) => {
  return (
    <div className="consents">
      <CheckboxInput
        text={languageJSON?.consentRegulations}
        checkConsent={consents?.toggleConsentRegulations}
        isConsentChecked={consents?.isConsentRegulationsChecked}
        toggleConsents={() => {}}
        consents={consents}
        languageJSON={languageJSON}
        formError={formError}
        required={true}
        operator={operator}
        language={language}
        regulations={true}
      />
      <CheckboxInput
        text={parseConsent(
          languageJSON?.consentMarketing,
          getOperatorConsentName(operator, language, ConsentType.MARKETING),
          languageJSON
        )}
        checkConsent={consents?.toggleConsentMarketing}
        isConsentChecked={consents?.isConsentMarketingChecked}
        toggleConsents={() => {}}
        consents={consents}
        languageJSON={languageJSON}
        formError={formError}
        operator={operator}
        language={language}
      />
      <CheckboxInput
        text={parseConsent(
          languageJSON?.consentTradingOffers,
          getOperatorConsentName(operator, language, ConsentType.TRADING),
          languageJSON
        )}
        checkConsent={consents?.toggleConsentTradingOffers}
        isConsentChecked={consents?.isConsentTradingOffersChecked}
        toggleConsents={() => {}}
        consents={consents}
        languageJSON={languageJSON}
        formError={formError}
        operator={operator}
        language={language}
      />
      <div>
        {getLinkedClause(
          getOperatorClause(operator, language),
          getOperatorClauseLink(operator),
          language
        )}
      </div>
      <span className="label-container__required-legend">
        * {languageJSON?.fieldIsRequired}
      </span>
    </div>
  );
};

const CheckboxInput: FC<CheckboxProps> = (props) => (
  <div className="label-container">
    <div style={{ display: "flex" }}>
      <label
        className={
          "label-container__checkbox-container" +
          (props.formError &&
          props.required &&
          !props.consents?.isConsentRegulationsChecked
            ? " label-container__checkbox-container__error-consent"
            : "")
        }
      >
        <input
          onClick={() => {
            if (props.checkConsent) {
              props.checkConsent();
            }
          }}
          readOnly
          type="checkbox"
          checked={props.isConsentChecked}
        />
        <span className="checkmark"></span>
      </label>
      {props.required ? (
        <span className="label-container__required">*</span>
      ) : (
        ""
      )}
      <p>
        {props.regulations
          ? renderConsentText(props.text, props.language)
          : props.text}
        {props.showMore ? (
          <span
            className="label-container__more"
            onClick={() => props.toggleConsents()}
          >
            {props.isConsentExpanded
              ? props.languageJSON?.less
              : props.languageJSON?.more}
            <img
              className={
                "label-container__more__arrow " +
                (props.isConsentExpanded
                  ? "label-container__more__arrow--reverse"
                  : "")
              }
              src={BottomArrowPrimary}
              alt="Language arrow"
            />
          </span>
        ) : (
          ""
        )}
      </p>
    </div>
    <div className="more-consents">
      {props.isConsentExpanded
        ? renderConsent(
            props.languageJSON,
            props.formError,
            props.operator,
            props.language,
            props.consents
          )
        : ""}
    </div>
  </div>
);

export default CheckboxInput;
