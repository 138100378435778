import plMain from "../languages/pl/main.json";
import enMain from "../languages/en/main.json";
import plForm from "../languages/pl/form.json";
import enForm from "../languages/en/form.json";
import plFinish from "../languages/pl/finish.json";
import enFinish from "../languages/en/finish.json";
import { Options } from "../types/language";
import { ConsentType } from "../types/form";

export const getLanguageJSON = <T>(options: Options): T | null => {
  switch (options.language) {
    case "pl":
      switch (options.module) {
        case "main":
          return (plMain as unknown) as T;
        case "form":
          return (plForm as unknown) as T;
        case "finish":
          return (plFinish as unknown) as T;
      }
      break;
    case "en":
      switch (options.module) {
        case "main":
          return (enMain as unknown) as T;
        case "form":
          return (enForm as unknown) as T;
        case "finish":
          return (enFinish as unknown) as T;
      }
  }

  return null;
};

export const getOperator = (path: string) => {
  const supportedOperators = [
    "nexity", 
    // "innogy"
    ];

  try {
    const urlSplitted = path.split("/");
    const operator = urlSplitted[urlSplitted.length - 1];
    if (supportedOperators.includes(operator)) return operator;
    else return "nexity";
  } catch (e) {
    return "nexity";
  }
};

export const getOperatorConsentName = (
  operator: string,
  language: string,
  consentType: ConsentType
) => {
  if (operator !== "nexity") {
    switch (language) {
      case "en":
        return "innogy Polska S.A.";
      case "pl":
        return consentType === ConsentType.MARKETING
          ? "spółkę innogy Polska S.A."
          : "spółki innogy Polska S.A.";
    }
  }

  return null;
};

export const getOperatorClause = (operator: string, language: string) => {
  switch (operator) {
    case "innogy":
      switch (language) {
        case "pl":
          return "innogy Polska S.A. jest administratorem Twoich danych osobowych znajdujących się w formularzu i są one przetwarzane m. in. w celu wykonania Twojego zapytania. Informacje o Twoich prawach, danych kontaktowych naszych i inspektora ochrony danych, podstawie prawnej przetwarzania, odbiorcach Twoich danych, okresie przechowywania oraz dobrowolności podania danych i konsekwencjach ich niepodania znajdują się {%link%}";
        case "en":
        default:
          return "innogy Polska S.A. is the controller of your personal data contained in the form and they are processed, among others in order to fulfill your inquiry. Information about your rights, our contact details and our data protection officer contact details, legal basis for processing, recipients of your data, storage period and voluntary data submission and the consequences of not providing them are available {%link%}";
      }
    default:
      return "";
  }
};

export const getOperatorClauseLink = (operator: string) => {
  switch (operator) {
    case "innogy":
      return "https://www.innogy.pl/pl/info-obligation?lang=pl&amp;version=002&amp;channel=Z13";
    default:
      return "";
  }
};
