import React, { FC, useEffect, useState } from "react";
import { NumberInputProps } from "../../types/input";
import Select, { components } from "react-select";
import SeparatorIcon from "../../assets/separator-line.svg";
import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import classNames from "classnames";
import SingleValue from "react-select/src/components/SingleValue";

const prefixOptions: any[] = [];
const countries = getCountries();
for (const i in countries) {
  const countryCallingCode = getCountryCallingCode(countries[i]);
  prefixOptions.push({
    value: i,
    country: countries[i],
    prefix: countryCallingCode,
    label: `${countries[i]} ${countryCallingCode}`,
  });
}

let defaultOption: any = {
  value: prefixOptions.indexOf((option: any) => option.country === "PL"),
  country: "PL",
  prefix: 48,
};

try {
  const option = prefixOptions.find(
    (option: any) => option.country === navigator.language.split("-")[1]
  );
  if (option) {
    defaultOption = option;
  }
} catch (e) {
  console.error(e);
}

const NumberInput: FC<NumberInputProps> = (props) => {
  const [validateError, setValidateError] = useState("");
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setValidateError("");
  }, [props.refresh]);

  useEffect(() => {
    props.setPrefix(defaultOption.prefix);
  }, []);

  const onChange = (e: any) => {
    props.onChange(props.name, e.target.value);
  };

  const onDefocus = (e: any) => {
    const value = e.target.value;

    if (props.validateRegex)
      if (value && !value.match(props.validateRegex)) {
        setValidateError(props.validateError || "");
        if (props.setValidation) props.setValidation(props.name, false);
      } else {
        setValidateError("");

        if (props.setValidation) props.setValidation(props.name, true);
      }
  };

  const handleChange = (option: any) => props.setPrefix(option.prefix);

  const SingleValue = ({ children, ...props }: any) => {
    return (
      <components.SingleValue {...props}>
        {`+${props.data.prefix}`}
      </components.SingleValue>
    );
  };

  const Option = ({ children, ...props }: any) => {
    return (
      <components.Option {...props}>
        <span>{props.data.country}</span>
        <span className="select__dropdown__calling-node">
          +{props.data.prefix}
        </span>
      </components.Option>
    );
  };

  const customStyle = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      outline: "transparent",
      borderRadius: "8px",
      height: "3.1rem",
      justifyContent: "default",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0,
      margin: 0,
      marginLeft: "1rem",
      marginRight: "1rem",
      fontSize: "14px",
      lineHeight: "25px",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      margin: 0,
      maxWidth: "default",
    }),
    menu: (base: any, state: any) => ({
      ...base,
      width: "12rem",
      padding: "0.3em 0.2rem 0.3em 0",
      borderRadius: "8px",
      fontSize: "14px",
      border: "1px solid #1e1e1e",
      lineHeight: "22px",
      fontWeight: "400",
      fontFamily: "Poppins, sans-serif",
    }),
    option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...base,
        backgroundColor: isSelected ? "#E4E4EB" : isFocused ? "none" : "",
        ":active": {
          backgroundColor: "#f4f4f4",
        },
        borderRadius: "4px",
        // marginTop: "0",
        paddingTop: "0.2rem",
        paddingBottom: "0.2rem",
        color: "#1f1f1f",
        display: "flex",
        justifyContent: "space-between",
        paddingRight: 0,
        marginRight: "0.3rem",
        width: "-webkit-fill-available",
        paddingLeft: "0.5rem",
        marginLeft: "0.3rem",
      };
    },
  };

  return (
    <label className="label">
      <span className="label__description">{props.label}</span>
      <div className="label__field">
        <div
          className={classNames(
            "select",
            {
              "select--focused": focus,
              "select--error": props.formError && !props.formData[props.name],
            },
            {
              "select--error":
                (props.formError && !props.formData[props.name]) ||
                validateError,
            }
          )}
          onClick={() => setFocus(true)}
          onBlur={(e: any) => {
            onDefocus(e);
            setFocus(false);
          }}
        >
          <span className="select__picker">
            <Select
              placeholder=""
              delimiter=""
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                SingleValue,
                Option,
              }}
              styles={customStyle}
              options={prefixOptions}
              defaultValue={defaultOption}
              onChange={handleChange}
              menuPlacement="auto"
            />
          </span>
          <img src={SeparatorIcon} alt="line" />
          <input
            name={props.name}
            onChange={onChange}
            className="select__input"
            placeholder="123 456 789"
            value={props.formData[props.name]}
          />
        </div>
      </div>
      <span className="label__error">{validateError}</span>
    </label>
  );
};

export default NumberInput;
