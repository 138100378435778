import axios from "axios";

export interface FormData {
  name: string;
  surname: string;
  email: string;
  number: string;
  password: string;

  companyId?: string;
  companyName?: string;
  country?: string;
  address?: string;
  city?: string;
  zipCode?: string;
}

interface RegisterDataApi {
  sellerId: number;

  wantRecharge: boolean;
  mobile: string;
  email: string;
  language: string;
  nameFirst: string;
  nameLast: string;
  company?: string;
  wantHardware: boolean;
  brand: string;
  draft: boolean;
  address?: {
    additional?: string;
    country?: string;
    street?: string;
    city?: string;
    zipcode?: string;
  };
  skipWelcomeEmail: boolean;
  newsletter: boolean;
  marketingConsent: boolean;
  customFieldInformation?: string;
}

//version for form API
// interface RegisterDataForm {
//   seller: number;

//   nameFirst: string;
//   nameLast: string;
//   email: string;
//   mobile: string;
//   password: string;
//   marketingConsent: boolean;
//   country: string;

//   customFieldInformation?: string; //NIP
//   company?: string;
//   streetaddress?: string;
//   city?: string;
//   zipcode?: string;
// }

interface Consents {
  regulations: boolean;
  marketing: boolean;
  trading: boolean;
}

// const urlMap = new Map<string, string>();
// urlMap.set(
//   "nexity",
//   "https://cors-anywhere.herokuapp.com/https://nexity.register.virtaglobal.com/register/form?site=nexity"
// );
// urlMap.set("innogy", "https://api.virta.fi/v4/admin/customers");

// urlMap.set(
//   "innogy",
//   "https://cors-anywhere.herokuapp.com/https://nexity_innogy.register.virtaglobal.com/register/form?site=nexity"
// );

//version for form API
// const mapDataForm = (
//   formData: FormData | undefined,
//   operator: string,
//   language: string,
//   consents: Consents
// ) => {
//   if (!formData) return null;

//   const resolvedLanguage = language && language.length === 2 ? language : "en";

//   const data: RegisterDataForm = {
//     seller: operator === "innogy" ? 1485 : 93,
//     nameFirst: formData.name,
//     nameLast: formData.surname,
//     email: formData.email,
//     mobile: `+${formData.number}`,
//     marketingConsent: consents.marketing,
//     country: resolvedLanguage === "pl" ? "Poland" : "England",
//     password: formData.password,
//   };

//   if (formData.companyId) {
//     data.customFieldInformation = formData.companyId;
//     data.company = formData.companyName;
//     data.streetaddress = formData.address;
//     data.city = formData.city;
//     data.zipcode = formData.zipCode;
//   }

//   return data;
// };

const mapDataApi = (
  formData: FormData | undefined,
  prefix: string,
  operator: string,
  language: string,
  consents: Consents
) => {
  if (!formData) return null;

  const resolvedLanguage = language && language.length === 2 ? language : "en";

  const data: RegisterDataApi = {
    sellerId: operator === "innogy" ? 1485 : 93,
    wantRecharge: false,
    wantHardware: false,
    draft: true,
    brand: "nexity",
    skipWelcomeEmail: false,

    mobile: `+${prefix}${formData.number}`,
    email: formData.email,
    language: resolvedLanguage,
    nameFirst: formData.name,
    nameLast: formData.surname,

    newsletter: consents.trading,
    marketingConsent: consents.marketing,
  };

  if (formData.companyId) {
    data.address = {
      additional: formData.companyId,
      country: formData.country,
      street: formData.address,
      city: formData.city,
      zipcode: formData.zipCode,
    };
    data.company = formData.companyName;
    data.customFieldInformation = formData.companyId;
  }

  return data;
};

//version for form API
// const parseError = (error: any) => {
//   const geneMessage = "Spróbuj ponownie poźniej";
//   if (
//     !error?.response?.data?.errors ||
//     !Array.isArray(error.response.data.errors) ||
//     error.response.data.errors.length === 0
//   )
//     return geneMessage;
//   return error.response.data.errors[0] || geneMessage;
// };

const parseError = (error: any, language: string) => {
  const geneMessage =
    language === "pl"
      ? "Upewnij się że podany E-mail istnieje i/lub spróbuj ponownie poźniej"
      : "Please make sure E-mail exists and/or try again later";
  let errors = null;

  if (!error?.response?.data?.validation?.errors) return geneMessage;

  errors = error.response.data.validation.errors;
  if (!Array.isArray(errors) || errors.length === 0) return geneMessage;

  return errors[0] || geneMessage;
};

export const register = async (
  formData: FormData | undefined,
  prefix: string | undefined,
  operator: string,
  consents: Consents,
  language: string,
  onSuccess: any,
  onError: any
) => {
  if (!prefix) return;

  const data = mapDataApi(formData, prefix, operator, language, consents);

  if (!data) return;

  try {
      const response = await axios.get(`${process.env.REACT_APP_AUTH_API_BASE_URL}${process.env.REACT_APP_AUTH_API_TOKEN_ENDPOINT}`,
      {
        headers: {
        'x-api-Key': process.env.REACT_APP_AUTH_API_KEY,
      },
    });
    const { data: resData } = response;
    if (resData?.info !== 'success') return;

    if (resData?.token) {
      await axios.post(
        `${process.env.REACT_APP_REGISTER_URL}`, data, {
          headers: {
            Authorization: `Bearer ${resData.token}`,
          },
        }
      );
    }
  } catch (e) {
    const error = parseError(e, language);
    onError(error);
    return;
  }

  onSuccess();
};
