import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import { isBrowser, isMobileOnly } from "react-device-detect";
import { useHistory } from "react-router-dom";
import LeftArrow from "../../assets/left-arrow.svg";
import InnogyLogo from "../../assets/operators/innogy-logo.svg";
import CheckboxInput from "../../components/inputs/checkboxInput";
import NumberInput from "../../components/inputs/numberInput";
import TextInput from "../../components/inputs/textInput";
import ToastNotification from "../../components/toastNotification";
import { FormData, register } from "../../lib/register";
import { getLanguageJSON, getOperator } from "../../lib/utils";
import { Form } from "../../types/language";

const renderInvidualInputs = (
  setPrefix: any,
  languageJSON: Form | null,
  updateFormDate: any,
  formData: any,
  formError: boolean,
  operator: string,
  refresh: number,
  setValidation: (name: string, state: boolean) => void
) => (
  <div>
    <TextInput
      name="name"
      onChange={updateFormDate}
      label={languageJSON?.name || "First name"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="surname"
      onChange={updateFormDate}
      label={languageJSON?.surname || "Surname"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="email"
      onChange={updateFormDate}
      label={languageJSON?.email || "E-mail"}
      formData={formData}
      formError={formError}
      validateRegex={/^[^@\s]+@[^@\s]{0,}\.[^@.\s]+$/}
      validateError={languageJSON?.validateEmail}
      refresh={refresh}
      setValidation={setValidation}
    />
    {
      //version for form API
      /* <TextInput
      name="password"
      type="password"
      onChange={updateFormDate}
      label={languageJSON?.password || "Password"}
      formData={formData}
      formError={formError}
      validateRegex={
        operator === "nexity"
          ? /^[0-9]{4}$/
          : /^(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[0-9]{1,})(?=.*^[a-zA-Z\d]{8,}$).*$/
      }
      validateError={
        operator === "nexity"
          ? languageJSON?.validatePasswordNexity
          : languageJSON?.validatePasswordInnogy
      }
    /> */
    }
    <NumberInput
      name="number"
      label={languageJSON?.phone || "Phone number"}
      onChange={updateFormDate}
      setPrefix={setPrefix}
      formData={formData}
      formError={formError}
      refresh={refresh}
      validateRegex={/^[0-9]*$/}
      validateError={languageJSON?.validateNumber}
      setValidation={setValidation}
    />
  </div>
);

const renderBusinessInputs = (
  setPrefix: any,
  languageJSON: Form | null,
  updateFormDate: any,
  formData: any,
  formError: boolean,
  operator: string,
  refresh: number,
  setValidation: (name: string, state: boolean) => void
) => (
  <div>
    <TextInput
      name="companyId"
      onChange={updateFormDate}
      label={languageJSON?.companyId || "Company ID"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="companyName"
      onChange={updateFormDate}
      label={languageJSON?.companyName || "Company Name"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="country"
      onChange={updateFormDate}
      label={languageJSON?.country || "Country"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="address"
      onChange={updateFormDate}
      label={languageJSON?.address || "Address"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <div>
      <TextInput
        name="city"
        onChange={updateFormDate}
        label={languageJSON?.city || "City"}
        formData={formData}
        formError={formError}
        refresh={refresh}
      />
      <TextInput
        name="zipCode"
        onChange={updateFormDate}
        label={languageJSON?.zipCode || "ZIP Code"}
        formData={formData}
        formError={formError}
        placeholder="12-345"
        validateRegex={/^\d{1,}[-\s]{1,}\d{1,}$/}
        validateError={languageJSON?.validateZipCode}
        refresh={refresh}
        setValidation={setValidation}
      />
    </div>
    <TextInput
      name="name"
      onChange={updateFormDate}
      label={languageJSON?.name || "First name"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="surname"
      onChange={updateFormDate}
      label={languageJSON?.surname || "Surname"}
      formData={formData}
      formError={formError}
      refresh={refresh}
    />
    <TextInput
      name="email"
      onChange={updateFormDate}
      label={languageJSON?.email || "E-mail"}
      formData={formData}
      formError={formError}
      validateRegex={/^[^@\s]+@[^@\s]{0,}\.[^@.\s]+$/}
      validateError={languageJSON?.validateEmail}
      refresh={refresh}
      setValidation={setValidation}
    />
    {
      //version for form API
      /* <TextInput
      name="password"
      type="password"
      onChange={updateFormDate}
      label={languageJSON?.password || "Password"}
      formData={formData}
      formError={formError}
      validateRegex={
        operator === "nexity"
          ? /^[0-9]{4}$/
          : /^(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[0-9]{1,})(?=.*^[a-zA-Z\d]{8,}$).*$/
      }
      validateError={
        operator === "nexity"
          ? languageJSON?.validatePasswordNexity
          : languageJSON?.validatePasswordInnogy
      }
    /> */
    }
    <NumberInput
      name="number"
      label={languageJSON?.phone || "Phone number"}
      onChange={updateFormDate}
      setPrefix={setPrefix}
      formData={formData}
      formError={formError}
      refresh={refresh}
      validateRegex={/^[0-9]*$/}
      validateError={languageJSON?.validateNumber}
      setValidation={setValidation}
    />
  </div>
);

const MobileForm: FC<{ language: string }> = (props) => {
  const [prefix, setPrefix] = useState();
  const [isBusiness, setIsBusiness] = useState(false);
  const [isConsentExpanded, setIsConsentExpanded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [formData, setFormData] = useState<FormData>({
    name: "",
    surname: "",
    email: "",
    number: "",
    password: "",
  });
  const [formError, setFormError] = useState(false);
  const [isConsentRegulationsChecked, setIsConsentRegulationsChecked] =
    useState(false);
  const [isConsentMarketingChecked, setIsConsentMarketingChecked] =
    useState(false);
  const [isConsentTradingOffersChecked, setIsConsentTradingOffersChecked] =
    useState(false);
  const [isAllConsentsChecked, setIsAllConsentsChecked] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [validation, validate] = useState({
    email: false,
    zipCode: false,
  });

  const history = useHistory();

  const setValidation = (name: string, state: boolean) =>
    validate({ ...validation, [name]: state });

  const operator = getOperator(history.location.pathname);
  const languageJSON: Form | null = getLanguageJSON<Form>({
    language: props.language,
    module: "form",
  });

  const exitToast = () => setToastContent("");
  const handleToClick = (to: string) => {
    exitToast();
    history.push(to);
  };

  const updateFormData = (key: string, value: string) => {
    setFormData({ ...(formData as FormData), [key]: value });
  };

  const handleSubmit = () => {
    let error: boolean = false;

    if (!validation.email) error = true;

    if (isBusiness && !validation.zipCode) error = true;

    if (!isConsentRegulationsChecked) error = true;

    if (!(formData as any).name) {
      error = true;
    }
    if (!(formData as any).surname) {
      error = true;
    }
    if (!(formData as any).email) {
      error = true;
    }
    if (!(formData as any).number) {
      error = true;
    }
    if (!prefix) {
      error = true;
    }

    if (isBusiness) {
      if (!(formData as any).companyId) {
        error = true;
      }
      if (!(formData as any).companyName) {
        error = true;
      }
      if (!(formData as any).address) {
        error = true;
      }
      if (!(formData as any).city) {
        error = true;
      }
      if (!(formData as any).zipCode) {
        error = true;
      }
    }

    if (error) {
      setFormError(true);
      if (!isConsentRegulationsChecked) setIsConsentExpanded(true);

      return;
    }

    exitToast();
    setProcessing(true);
    register(
      formData,
      prefix,
      operator,
      {
        regulations: isConsentRegulationsChecked,
        marketing: isConsentMarketingChecked,
        trading: isConsentTradingOffersChecked,
      },
      props.language,
      () => handleToClick("/finish"),
      (msg: string) => {
        setToastContent(msg);
        setProcessing(false);
      }
    );
  };

  const toggleConsents = () => setIsConsentExpanded(!isConsentExpanded);
  const toggleAllConsents = () => {
    setIsAllConsentsChecked(!isAllConsentsChecked);
    setIsConsentRegulationsChecked(!isAllConsentsChecked);
    setIsConsentMarketingChecked(!isAllConsentsChecked);
    setIsConsentTradingOffersChecked(!isAllConsentsChecked);
  };

  const toggleConsentRegulations = () => {
    if (
      !isConsentRegulationsChecked &&
      isConsentMarketingChecked &&
      isConsentTradingOffersChecked
    )
      setIsAllConsentsChecked(true);
    else setIsAllConsentsChecked(false);
    setIsConsentRegulationsChecked(!isConsentRegulationsChecked);
  };
  const toggleConsentMarketing = () => {
    if (
      isConsentRegulationsChecked &&
      !isConsentMarketingChecked &&
      isConsentTradingOffersChecked
    )
      setIsAllConsentsChecked(true);
    else setIsAllConsentsChecked(false);

    setIsConsentMarketingChecked(!isConsentMarketingChecked);
  };
  const toggleConsentTradingOffers = () => {
    if (
      isConsentRegulationsChecked &&
      isConsentMarketingChecked &&
      !isConsentTradingOffersChecked
    )
      setIsAllConsentsChecked(true);
    else setIsAllConsentsChecked(false);
    setIsConsentTradingOffersChecked(!isConsentTradingOffersChecked);
  };

  return (
    <div
      className={
        "m-form-container" + (isMobileOnly ? " m-form-container__mobile" : "")
      }
    >
      <ToastNotification content={toastContent} clear={exitToast} />
      <div className="m-form-container__form-nav">
        <button
          className="m-form-container__form-nav__button"
          onClick={() => handleToClick("/")}
        >
          <img
            className="m-form-container__form-nav__button__arrow"
            src={LeftArrow}
            alt="Left arrow"
          />
          <h2 className="m-form-container__form-nav__back">
            {languageJSON?.create}
          </h2>
        </button>
        <img
          className="m-form-container__form-nav__logo"
          src={operator === "nexity" ? "/nexity-logo.svg" : InnogyLogo}
          alt="Nexity Logo"
        />
      </div>
      <div className="m-form-container__type">
        <button
          className={
            "m-form-container__type__button m-form-container__type__button__first" +
            (!isBusiness ? " m-form-container__type__button--active" : "") +
            (isBrowser ? " form-d-button" : "")
          }
          onClick={() => {
            setFormData({
              name: "",
              surname: "",
              email: "",
              number: "",
              password: "",
            });
            setFormError(false);
            setIsBusiness(false);
            doRefresh(refresh + 1);
          }}
        >
          <FontAwesomeIcon
            className="m-form-container__type__button__icon"
            size="lg"
            icon={faUser}
          />{" "}
          {languageJSON?.individual}
        </button>
        <button
          className={
            "m-form-container__type__button" +
            (isBusiness ? " m-form-container__type__button--active" : "") +
            (isBrowser ? " form-d-button" : "")
          }
          onClick={() => {
            setFormData({
              name: "",
              surname: "",
              email: "",
              number: "",
              password: "",
            });
            setFormError(false);
            setIsBusiness(true);
            doRefresh(refresh + 1);
          }}
        >
          <FontAwesomeIcon
            className="m-form-container__type__button__icon"
            size="lg"
            icon={faBuilding}
          />{" "}
          {languageJSON?.business}
        </button>
      </div>
      <form className="m-form-container__form" onSubmit={handleSubmit}>
        {isBusiness
          ? renderBusinessInputs(
              setPrefix,
              languageJSON,
              updateFormData,
              formData,
              formError,
              operator,
              refresh,
              setValidation
            )
          : renderInvidualInputs(
              setPrefix,
              languageJSON,
              updateFormData,
              formData,
              formError,
              operator,
              refresh,
              setValidation
            )}
        <CheckboxInput
          showMore={true}
          text={languageJSON?.consentAll}
          toggleConsents={toggleConsents}
          isConsentExpanded={isConsentExpanded}
          consents={{
            isConsentRegulationsChecked,
            isConsentMarketingChecked,
            isConsentTradingOffersChecked,
            toggleConsentRegulations,
            toggleConsentMarketing,
            toggleConsentTradingOffers,
            isAllConsentsChecked,
            toggleAllConsents,
          }}
          checkConsent={toggleAllConsents}
          isConsentChecked={isAllConsentsChecked}
          languageJSON={languageJSON}
          formError={formError}
          operator={operator}
          language={props.language}
        />
        <button
          type="button"
          onClick={handleSubmit}
          className={
            "m-form-container__form__button" +
            (processing ? " m-form-container__form__button__processing" : "")
          }
          disabled={processing}
        >
          {languageJSON?.next}
        </button>
      </form>
    </div>
  );
};

export default MobileForm;
