import React, { FC } from "react";
import MobileFinish from "../mobile/Finish";

const DesktopFinish: FC<{ language: string }> = (props) => {
  return (
    <div className="f-d-container">
      <div style={{ width: "22.5rem" }}>
        <MobileFinish language={props.language} />
      </div>
    </div>
  );
};

export default DesktopFinish;
